import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import pennantImage from './assets/images/pennant-mockup.png';
import footballImage from './assets/images/football-mockup-one.png';
import throwImage from './assets/images/throw-in-mockup.png';
import beachImage from './assets/images/BOSbeach.jpeg';
import BOSlogo from './assets/images/BOS.svg';
import ESAlogo from './assets/images/ESA Logo.svg';
import adamImage from './assets/images/adam.jpeg';
import ECFCImage from './assets/images/FC_Edinburgh.png';

function App() {
  // Initialize AOS on component mount
  useEffect(() => {
    AOS.init();
  }, []);

  const currentYear = new Date().getFullYear();

  // State to track form input values
  const [formData, setFormData] = useState({
    yourName: '',
    playerName: '',
    email: '',
    phone: '',
    comments: '',
  });

  // Function to handle form input changes
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://formspree.io/f/xzzparnk', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle successful submission
        console.log('Form submitted successfully!');
        setFormData({
          yourName: '',
          playerName: '',
          email: '',
          phone: '',
          comments: '',
        });
        // You can add code here to show a success message to the user 
      } else {
        // Handle error
        console.error('Error submitting form:', response.statusText);
        // You can add code here to show an error message to the user
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // You can add code here to handle network errors
    }
  };

  return (
    <div className="bg-black min-h-screen text-white">
      {/* Hero Section */}
      <section className="hero-section flex flex-col items-center justify-center px-4 relative">
        <div className="relative text-center">
          <h2 className="welcome-title text-5xl font-yellowtail text-gold absolute" data-aos="fade-up">
            Welcome to
          </h2>
          <h1 className="academy-title text-6xl font-anton text-white mt-10 relative" data-aos="fade-up">
            THE EDINBURGH <br />
            SPORTS ACADEMY
          </h1>
        </div>

        <div className="w-full max-w-4xl mt-8" data-aos="fade-up">
          <img
            src={pennantImage}
            alt="Edinburgh Sports Academy"
            className="w-full h-auto aspect-video object-cover rounded-lg"
          />
        </div>

        <p className="max-w-4xl text-center mt-6 text-lg font-poppins" data-aos="fade-up">
        At The Edinburgh Sports Academy, we are proud to be partnered with Edinburgh City FC and The British Online School, offering an unparalleled opportunity for young athletes. Our academy is the first of its kind—a pioneering educational institution that combines full-time private education with the demands of being a full-time athlete. We provide a direct pathway to semi-professional football opportunities, both here in Scotland and across England.


        </p>

        <div className="mt-6 flex justify-center" data-aos="fade-up">
        <button 
  className="signup-button bg-gold text-black font-poppins py-3 px-8 text-lg transform rotate-10"
  onClick={() => {
    const contactSection = document.getElementById('contact-us');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  }}
>
  SIGN UP
</button>
        </div>
      </section>

      {/* New White Section with Cards */}
      <section className="bg-white text-black p-20">
        <div className="max-w-6xl mx-auto">
          {/* Card 1 - Image on Right */}
          <div className="flex flex-col md:flex-row items-center mb-12" data-aos="fade-up">
            <div className="md:w-1/2">
              <h3 className="card-title text-3xl font-bold mb-4">A Unique Educational and Athletic Experience</h3>
              <p className="text-lg">
              In collaboration with The British Online School, we offer an outstanding, award-winning education that ensures our students receive the best academic support. Our partnership with Edinburgh City FC opens doors to competitive Club Academy Scotland teams and semi-professional football, giving students the chance to take their talents to the next level.
              </p>
            </div>
            <div className="md:w-1/2 md:ml-8 mt-6 md:mt-0">
              <img src={throwImage} alt="Card 1" className="w-full h-auto rounded-lg" />
            </div>
          </div>

          {/* Card 2 - Image on Left */}
          <div className="flex flex-col md:flex-row-reverse items-center mb-12" data-aos="fade-up">
            <div className="md:w-1/2">
              <h3 className="card-title text-3xl font-bold mb-4">Expert Leadership and Seamless Integration</h3>
              <p className="text-lg">
              The founders and CEO of The British Online School, who are also majority shareholders in Edinburgh City FC, play a significant role in bringing these three key elements together. Their involvement ensures a smooth transition between the school, academy, and football club, creating a cohesive and supportive environment for all our students. Whether your child has exceptional sporting potential or academic ability, The Edinburgh Sports Academy is dedicated to discovering and nurturing their talents.
</p>
            </div>
            <div className="md:w-1/2 md:mr-8 mt-6 md:mt-0">
              <img src={footballImage} alt="Card 2" className="w-full h-auto rounded-lg" />
            </div>
          </div>

          {/* Card 3 - Image on Right */}
          <div className="flex flex-col md:flex-row items-center mb-12" data-aos="fade-up">
            <div className="md:w-1/2">
              <h3 className="card-title text-3xl font-bold mb-4">World-Class Training Led by Adam Cairnie</h3>
              <p className="text-lg">
              Our Head of Academy, Adam Cairnie, brings exceptional qualifications and experience to his role. He is deeply committed to the development of every student, providing top-level training that focuses on both the physical and tactical aspects of football. Under his expert guidance, students will see substantial growth in their skills and abilities, helping them to excel both on and off the field.
</p>
            </div>
            <div className="md:w-1/2 md:ml-8 mt-6 md:mt-0">
              <img src={adamImage} alt="Card 3" className="w-full h-auto rounded-lg" />
            </div>
          </div>

          {/* Card 4 - Image on Left */}
          <div className="flex flex-col md:flex-row-reverse items-center" data-aos="fade-up">
            <div className="md:w-1/2">
              <h3 className="card-title text-3xl font-bold mb-4">Preparing for the Future</h3>
              <p className="text-lg">
              With only 1% of academy players going on to professional football careers, it is more important than ever to provide a solid educational foundation. The Edinburgh Sports Academy offers just that—a comprehensive private education delivered by an award-winning institution, ensuring that our students are prepared for whatever the future holds.
</p>
            </div>
            <div className="md:w-1/2 md:mr-8 mt-6 md:mt-0">
              <img src={beachImage} alt="Card 4" className="w-full h-auto rounded-lg" />
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us CTA and Form Section */}
<section id="contact-us" className="contact-section bg-black text-white p-20">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-anton uppercase mb-6" data-aos="fade-up">
            Achieve Your Dreams
          </h2>
          <p className="text-lg font-poppins mb-8" data-aos="fade-up">
          By joining The Edinburgh Sports Academy, your child will benefit from full-time training and full-time education, bringing them closer than ever to achieving their dreams. We are committed to helping our students reach their full potential, both on the pitch and in the classroom.
</p>
<p className="text-lg font-poppins mb-8" data-aos="fade-up">
Sign up today to learn more about how The Edinburgh Sports Academy can help your child reach their full potential. Enter your email below to get started.

</p>


          <form onSubmit={handleSubmit} className="flex flex-col space-y-4" data-aos="fade-up">
            <input
              type="text"
              name="yourName"
              placeholder="Your Name"
              value={formData.yourName}
              onChange={handleChange}
              className="contact-input"
            />
            <input
              type="text"
              name="playerName"
              placeholder="Player Name"
              value={formData.playerName}
              onChange={handleChange}
              className="contact-input"
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="contact-input"
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              className="contact-input"
            />
            <textarea
              name="comments"
              placeholder="Other Comments"
              value={formData.comments}
              onChange={handleChange}
              className="contact-input"
            />
            <button type="submit" className="submit-button bg-gold text-black font-anton py-3 px-8 text-lg transform rotate-10">
              Submit
            </button>
          </form>
        </div>
      </section>
      <div className="flex flex-row items-center justify-center mt-8"> 
  <div className="flex flex-col items-center mr-8"> 
    <p className="text-sm font-poppins mb-2">Powered by</p>
  <a href="https://britishonlineschool.co.uk/" target="_blank" rel="noreferrer">
    <img src={BOSlogo} alt="BOS Logo" className="w-32 h-auto" />
  </a>
</div>
<div className="flex flex-col items-center">
    <p className="text-sm font-poppins mb-2">Backed by</p>
    <a href="https://edinburghcityfc.com" target="_blank" rel="noopener noreferrer"> {/* Replace with your actual link */}
    <img src={ECFCImage} alt="ECFC Logo" className="w-32 h-auto" /> {/* Replace with your logo path and alt text */}
    </a>
    </div>

    </div>

 {/* Footer */}
 <footer className="bg-black text-white text-center py-4">
        <p className="text-sm font-poppins">
          &copy; {currentYear} The Edinburgh Sports Academy. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default App;
